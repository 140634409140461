
import { api } from "@/api/api";
import { ApiGetChangedCourseParticipantApplicationDto, ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { DashboardUser } from "@/shared/constants/dashboardUser";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { formatLocalizedDateP } from "@/shared/helpers/dateHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  components: { BaseLayout, BaseTableFiltered },
  name: "NewApplicationsPage",
  props: {
    groupBy: String as PropType<string | null>,
  },
  setup() {
    const userId = Number(window.sessionStorage.getItem(DashboardUser.USER_ID));
    const store = useStore<StoreState>();
    const localStorageName = "changedCourseParticipantApplicationsGroupBy";
    const selectedRows = ref<ApiGetChangedCourseParticipantApplicationDto[]>([]);
    const changedCourseParticipants = ref<ApiGetChangedCourseParticipantApplicationDto[]>([]);
    const isFilterOpen = ref(false);
    const isLoading = ref(false);
    const memberOrganizations = ref<ApiGetMemberOrganizationDto[]>([]);
    const currentGroupBy = ref<string | null>(localStorage.getItem(localStorageName) || null);

    const updateGroupBy = (value: string | null) => {
      if (value) {
        currentGroupBy.value = value;
        localStorage.setItem(localStorageName, value);
      } else {
        currentGroupBy.value = null;
        localStorage.removeItem(localStorageName);
      }
    };

    const dashboardLink = () => useNavigateBack(DashboardRouteNames.Home);

    const getParticipantsLink = (courseId: string) => ({
      name: SingleCourseRouteNames.CourseParticipants,
      params: {
        id: courseId,
      },
    });

    const getOrgName = (orgId: number) => {
      const memberOrganization = memberOrganizations.value.find((organization) => organization.id === orgId);

      return memberOrganization ? memberOrganization.name : "-";
    };

    const markAsRead = async () => {
      const changedApplications = selectedRows.value.map((application) => application.changeId);
      isLoading.value = true;
      await api.dashboard.markChangedCourseParticipantApplicationsRead({ changeIds: changedApplications }, { userId });
      openNotification(store, NotificationItemType.Success, "Endrede deltakere ble merket som lest.");
      await getChangedCourseParticipantApplications();
      selectedRows.value = [];
      isLoading.value = false;
    };

    const fetchMemberOrganizations = async () => {
      memberOrganizations.value = (await api.organization.getMemberOrganizationsAsync()).data;
    };

    const getChangedCourseParticipantApplications = async () => {
      changedCourseParticipants.value = (await api.dashboard.getChangedCourseParticipantApplications({ userId })).data;
    };

    const formatGroupBy = (group: string) => {
      if (currentGroupBy.value) {
        // Get the group by header
        const headerItem = headers.find((x) => x.value === currentGroupBy.value);

        if (currentGroupBy.value === "memberOrganizationId") {
          return `${headerItem && `${headerItem.text}:`} ${getOrgName(+group)}`;
        }

        return `${headerItem && `${headerItem.text}:`} ${group}`;
      }
    };

    onMounted(async () => {
      isLoading.value = true;
      await Promise.all([getChangedCourseParticipantApplications(), fetchMemberOrganizations()]).then(() => {
        isLoading.value = false;
      });
    });

    return {
      currentGroupBy,
      headers,
      isFilterOpen,
      selectedRows,
      changedCourseParticipants,
      dashboardLink,
      markAsRead,
      getParticipantsLink,
      formatLocalizedDateP,
      hasMemberOrgAccess,
      getOrgName,
      updateGroupBy,
      isLoading,
      formatGroupBy,
    };
  },
});

const headers = [
  {
    text: "Id",
    value: "courseId",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Tittel",
    value: "courseName",
  },
  {
    text: "Navn på søker/deltaker",
    value: "applicantName",
  },
  {
    text: "Medlemsorganisasjon",
    value: "memberOrganizationId",
  },
  {
    text: "Søknadsbehandler",
    value: "guestUserName",
  },
  {
    text: "Kursadministrator",
    value: "lecturerName",
  },
  {
    text: "Startdato",
    value: "startDate",
  },
  {
    text: "Søknadsstatus",
    value: "applicationStatus",
  },
  {
    text: "Kommentar",
    value: "applicationStatusComment",
  },
  {
    text: "Søknadsfrist",
    value: "enrollmentDeadline",
  },
];
