var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    attrs: {
      "displayTabs": _vm.isFilterOpen
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "mr-2",
          on: {
            "click": _vm.dashboardLink
          }
        }, [_vm._v(" mdi-arrow-left")]), _vm._v(" Endrede deltakere ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.selectedRows.length > 0 ? _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "loading": _vm.isLoading,
            "outlined": "",
            "data-cy": "markAsReadBtn"
          },
          on: {
            "click": function click($event) {
              return _vm.markAsRead();
            }
          }
        }, [_vm._v(" Marker som sett ")]) : _vm._e(), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "loading": _vm.isLoading
          },
          on: {
            "click": function click($event) {
              _vm.isFilterOpen = !_vm.isFilterOpen;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter-variant")]), _vm._v(" Filter ")], 1)];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [!_vm.currentGroupBy ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "loading": _vm.isLoading,
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateGroupBy('courseId');
            }
          }
        }, [_vm._v(" Grupper etter kurs ")]) : _vm._e(), !_vm.currentGroupBy ? _c('v-btn', {
          attrs: {
            "loading": _vm.isLoading,
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateGroupBy('memberOrganizationId');
            }
          }
        }, [_vm._v(" Grupper etter medlemsorganisasjon ")]) : _vm._e(), _vm.currentGroupBy ? _c('v-btn', {
          attrs: {
            "loading": _vm.isLoading,
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateGroupBy(null);
            }
          }
        }, [_vm._v(" Fjern gruppering ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.changedCourseParticipants,
      "group-by": _vm.currentGroupBy,
      "sort-by": ['courseId'],
      "item-key": "changeId",
      "show-select": "",
      "loading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "groupHeader",
      fn: function fn(_ref) {
        var group = _ref.group;
        return [_vm._v(" " + _vm._s(_vm.formatGroupBy(group)) + " ")];
      }
    }, {
      key: "item.startDate",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDateP(value)) + " ")];
      }
    }, {
      key: "item.enrollmentDeadline",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDateP(value)) + " ")];
      }
    }, {
      key: "item.memberOrganizationId",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(_vm.getOrgName(value)) + " ")];
      }
    }, {
      key: "item.applicationStatusComment",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [value ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("mdi-message")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(value))])]) : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                  attrs = _ref8.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "color": "primary",
                  "to": _vm.getParticipantsLink(item.courseId)
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis deltakerliste")])])];
      }
    }], null, true),
    model: {
      value: _vm.selectedRows,
      callback: function callback($$v) {
        _vm.selectedRows = $$v;
      },
      expression: "selectedRows"
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }